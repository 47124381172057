/* 
  最新动态相关接口
*/

import req from '@/utils/request' //引入暴露出来的请求函数

export default {
  // 获取最新动态列表
  findRecentNewsList: params => req.get('/api/latestNews/list/all', params),
  // 获取最新动态详情
  getRecentNewsDetail: params => req.get('/api/latestNews/detail', params),


}
